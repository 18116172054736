<template>
  <div :id="id" class="modal fade">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <modal-close-button @confirmed="closeModal" />
          <h4>
            <i class="fad fa-exclamation-triangle text-warning mr-2"></i>
            {{ multiText ? "Datensätze löschen" : "Datensatz löschen" }}
          </h4>
        </div>
        <div class="modal-body">
          {{
            multiText
              ? "Wollen sie diese Datensätze wirklich löschen?"
              : "Wollen sie diesen Datensatz wirklich löschen?"
          }}
        </div>
        <div class="modal-footer">
          <button class="btn btn-default" @click="closeModal">Abbrechen</button>
          <button class="btn btn-danger" @click="loeschen">
            Löschen
            <span v-if="selectedIds.length > 0"
              >({{ selectedIds.length }})</span
            >
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalCloseButton from "@/components/global/ModalCloseButton";

export default {
  name: "LoeschenModal",
  props: {
    id: {
      type: String,
      required: false,
      default: "modal-loeschen",
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectedIds: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      shown: false,
      multiText: false,
    };
  },
  components: {
    ModalCloseButton,
  },
  computed: {},
  mounted() {
    this.$bus.$on("openLoeschenModal", (id) => {
      if (this.selectedIds.length > 1) this.multiText = true;
      else if (this.multiple == true) this.multiText = true;

      if (!this.shown) this.openModal(id);
    });
  },
  methods: {
    openModal(id) {
      this.shown = true;

      const thisId = id ? id : this.id;

      $("#" + thisId).modal({
        backdrop: "static",
        keyboard: false,
      });
    },

    closeModal() {
      this.shown = false;
      $("#" + this.id).modal("hide");
    },

    loeschen() {
      this.$emit("confirmed");
      this.closeModal();
    },
  },
};
</script>

<style>
</style>