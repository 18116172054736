<template>
  <div class="list" @cleanTable="cleanTable">
    <notifications></notifications>

    <div class="row" id="equitiesWaehlen">
      <div class="container-fluid">
        <!--
        <container-headline
          headline="Multiply listed - Choose equities to keep"
          :col="12"
        >
        </container-headline>
        -->

        <!-- <div><h4>  Multiply listed - Choose Equities to keep : </h4></div> -->

        <div class="body">
          <div class="row">
            <div class="w-100-p">
              <b-table
                ref="selectableTable"
                small
                tbody-tr-class="item"
                :items="listings"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                sort-icon-left
                fixed
                selectable
                select-mode="range"
              >
                <template v-slot:head(selected)="row">
                  <b-form-checkbox
                    v-model="row.selected"
                    @input="selectAllRows(row)"
                  ></b-form-checkbox>
                </template>
                <template v-slot:cell(selected)="row">
                  <b-form-checkbox
                    v-model="row.item.selected"
                    @input="onRowSelected(row)"
                  ></b-form-checkbox>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  <loeschen-modal
      id="belege-loeschen-modal"
      @confirmed="loeschen"
      :multiple="true"
    /> -->
  </div>
</template>

<script>
//import DatePicker from "@/components/Datepicker";
import Api from "@/Api";
import { debounce } from "@/utils/Debouncer";
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import store from "@/store";
import page from "@/mixins/Page";
import InfiniteLoading from "vue-infinite-loading";
//import ContainerHeadline from "@/components/ContainerHeadline";
import FileDownload from "js-file-download";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import DatePicker from "@/components/Datepicker";
import numeral from "numeral";

import { insertUrlParam, removeUrlParam } from "@/utils/Url";

export default {
  name: "EquitiesWaehlen",
  components: {
    //ContainerHeadline,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "Universe ";
      },
    };
  },

  props: {
    listings: {
      type: Array,
      required: false,
      default: null,
    },
    refresh: {
      type: Boolean,
    },
  },
  store,

  data() {
    return {
      sortBy: "equity",
      sortDesc: false,
      fields: [
        { key: "selected", label: "" },
        { key: "equity", sortable: true, label: "Equity" },

        { key: "last_price", label: "Last Price" },
        {
          key: "epsY1",
          label: "EPSY1",
          formatter: (value) => this.formatNumbers(value),
        },
        {
          key: "epsY2",
          label: "EPSY2",
          formatter: (value) => this.formatNumbers(value),
        },
        {
          key: "epsY3",
          label: "EPSY3",
          formatter: (value) => this.formatNumbers(value),
        },
        {
          key: "market_cap",
          label: "Market Cap",
          formatter: (value) => this.formatNumbers(value),
        },
        {
          key: "salesY1",
          label: "SalesY1",
          formatter: (value) => this.formatNumbers(value),
        },
        {
          key: "salesY2",
          label: "SalesY2",
          formatter: (value) => this.formatNumbers(value),
        },
        {
          key: "salesY3",
          label: "SalesY3",
          formatter: (value) => this.formatNumbers(value),
        },
      ],
      selected: [],
      selectedIds: [],
      selectedObjects: [],
    };
  },
  computed: {
    queryParams: function () {
      var params = {};
      return params;
    },
  },
  watch: {
    filter: function (val) {
      if (this.showNavbar)
        val ? insertUrlParam("filter", val) : removeUrlParam("filter");
      this.resetLoading(this);
    },
    refreshTable: function () {
      console.log("this refreshtable called");
      this.$refs.selectableTable.refresh();
    },
  },
  created() {
    this.$bus.$on("cleanTable", () => {
      // console.log("clean Table child created");
      this.$refs.selectableTable.items.forEach((v) => {
        v.selected = false;
        //     console.log("im for each");
      });
      this.$store.state.selectedEquities = [];
      this.$forceUpdate();
      this.$refs.selectableTable.refresh();
    });
  },
  mounted() {},
  methods: {
    onRowSelected(row) {
      if (row.item.selected) {
        this.$store.state.selectedEquities.push(row.item.row_index);
      } else {
        this.$store.state.selectedEquities =
          this.$store.state.selectedEquities.filter(
            (row_index) => row_index != row.item.row_index
          );
      }
    },
    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((v) => {
          v.selected = true;
          if (!this.selectedIds.includes(v.id)) {
            this.selectedIds.push(v.id);
          }
        });
      } else {
        this.$refs.selectableTable.items.forEach((v) => {
          v.selected = false;
        });
        this.selectedIds = [];
      }
      this.$forceUpdate();
    },
    cleanTable() {
      console.log("clean Table child fuction");
      this.$refs.selectableTable.items.forEach((v) => {
        v.selected = false;
      });
      this.$store.state.selectedEquities = [];
      this.$forceUpdate();
    },
    formatNumbers(value) {
      if (value) {
        return value.toLocaleString(undefined, { maximumFractionDigits: 2 });
      }
    },
  },
};
</script>

<style>
#equitiesWaehlen {
  font-family: Montserrat;
}
#equitiesWaehlen .w-100-p {
  height: 60vh;
  overflow-y: scroll;
  scrollbar-color: #8f98ae #c5cbdc;
  scrollbar-width: thin;
}
#equitiesWaehlen thead {
  background: #8f98ae;
  color: white;
  font-size: 13px;
  text-align: left;
  font-weight: 400;
}
#equitiesWaehlen tbody {
  background: white;
}
#equitiesWaehlen table > thead > tr > th {
  border: 1px solid #8f98ae;
}
#equitiesWaehlen table > thead > tr > th:first-child,
table > tbody > tr > td:first-child {
  padding-left: 20px;
}
#equitiesWaehlen table > tbody > tr > td:last-child,
table > tbody > tr > td:last-child {
  padding-right: 20px;
}
</style>
