<template>
  <div class="row">
    <div :class="[colClass]" class="block-dark block-kopf-dark">
      <div class="row">
        <div class="col-xl-4">
          <span class="primary-headline-text text-white">
            <i class="far fa-calendar-alt mr-2"></i>
            {{ timestamp }}
          </span>
        </div>
        <div class="col-xl-4 text-center">
          <span class="primary-headline-text text-white">{{ headline }}</span>
        </div>
        <div class="col-xl-4 text-right" v-if="anzahl">
          <span v-if="anzahlAusFilter" class="primary-headline-text text-white"
            >Daten: {{ anzahlAusFilter }} von {{ anzahl }}</span
          >
          <span v-if="!anzahlAusFilter" class="primary-headline-text text-white"
            >Daten: 0 von {{ anzahl }}</span
          >
        </div>
        <div class="col-xl-4 text-right" v-if="showTime">
          <span class="primary-headline-text text-white">{{ clockstamp }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { insertUrlParam, removeUrlParam } from "@/utils/Url";
export default {
  name: "HeadMenu",
  props: {
    headline: {
      type: String,
      required: true,
    },
    col: {
      type: Number,
      required: false,
      default: 2,
    },
    //anzeige Anzahl Datensätze im Headmenu
    anzahl: {
      type: Number,
      required: false,
      default: null,
    },
    anzahlAusFilter: {
      type: Number,
      required: false,
      default: null,
    },
    showTime: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      suche: this.$route.query.suche,
      timestamp: "",
      clockstamp: "",
    };
  },
  computed: {
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      if (this.suche) params.suche = this.suche;

      return params;
    },
    colClass: function () {
      if (this.col) {
        return "col-xl-" + this.col;
      } else {
        return "col-xl-2";
      }
    },
  },
  watch: {
    suche: function (val) {
      if (this.showNavbar)
        val ? insertUrlParam("suche", val) : removeUrlParam("suche");
      this.resetLoading(this);
    },
  },
  mounted() {
    this.getNow();
    this.getTime();
  },
  methods: {
    getNow: function () {
      const today = new Date();
      const date =
        new Date().toLocaleString("de", { weekday: "long" }) +
        " " +
        today.getDate() +
        ". " +
        new Date().toLocaleString("de", { month: "long" }) +
        " " +
        today.getFullYear();

      this.timestamp = date;
    },

    getTime: function () {
      const today = new Date();
      const time = new Date().toLocaleTimeString("de", {});

      this.clockstamp = time;
    },
  },
};
</script>

<style>
</style>
