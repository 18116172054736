<template>
  <div class="kopf" :class="{ 'bg-turkis': einkauf }">
    <div class="container-fluid">
      <span class="HeaderTitle text-center">Arvea Universe Calculator</span>
      <div class="left-buttons">
        <div>
          <button class="btn btn-primary mt-3 mr-2" @click="oeffneAlertModal">
            Calculate
          </button>
        </div>
      </div>
      <div class="right-buttons">
        <div class="navbar-button">
          <a href="#" onclick="window.location.reload(true);">
            <i class="icon-Refresh" aria-hidden="false"></i>
            <span>Refresh</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import Slide from "@/components/Navigation/Menu/Slide";
import Api from "@/Api";

import axios from "axios";

import server from "../server";
export default {
  name: "Navbar",
  components: {
    //  Slide,
  },
  props: {
    title: { type: String, required: false, default: "" },
    icon: { type: String, default: null },
    einkauf: { type: Boolean, default: false },
    finanzen: { type: Boolean, default: false },
    erinnerungConfig: {
      type: Object,
      default: () => {
        return { url: "", titel: "" };
      },
    },
  },
  store,
  computed: {
    name() {
      return this.$store ? this.$store.getters.name : "";
    },
    artikeltypen() {
      return this.$store.state.artikeltypen;
    },
  },
  methods: {
    oeffneAlertModal() {
      this.$parent.erstelleListenFuerModal();
      this.$parent.oeffneAlertModal();
    },
    oeffneLogoutModal() {
      this.$parent.logout();
    },
  },
};
</script>

<style lang="scss">
.bm-item-list {
  margin-left: 5%;
}
</style>
