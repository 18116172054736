<template>
  <div class="mt-0-px pb-0" v-if="!isExtern">
    <div id="calculator" v-if="angemeldeterMitarbeiter">
      <navigation-arvea :angemeldeterMitarbeiter="angemeldeterMitarbeiter" />
      <div class="row banner">
        <div class="col-3"></div>
        <div class="col-6">
          <h1>ARVEA Universe Calculator</h1>
          <p>
            Arvea Investments AG Is A Swiss Fintech Investment Advisory Firm
            Using Proprietary Non-Linear Models. Arvea Investments AG Was
            Founded To Deliver Innovative And Unique Financial Investment
            Products To Meet The Needs Of Investors In This Changing Market
            Environment.
          </p>
        </div>
        <div class="col-3">
          <img src="https://arvea-dev.batix.ch/pic/17CC2307C96_236x206.png" />
        </div>
      </div>
      <div class="wrapper calculateTool">
        <div class="row d-flex justify-content-between">
          <div class="col-2">
            <p>01</p>
            <p>Upload</p>
          </div>
          <div class="col-3"><span /></div>
          <div class="col-2">
            <p>02</p>
            <p>Calculate</p>
          </div>
          <div class="col-3"><span /></div>
          <div class="col-2">
            <p>03</p>
            <p>Download</p>
          </div>
          <!--
          <div class="col-2">
            <p>01</p>
            <p>Upload</p>
            <span />
          </div>
          <div class="col-3">
            <span />
          </div>
          <div class="col-2">
            <p>02</p>
            <p>Calculate</p>
            <span />
          </div>
          <div class="col-3">
            <span />
          </div>
          <div class="col-2">
            <p>03</p>
            <p>Download</p>
          </div>
          -->
        </div>
        <div class="row">
          <div class="col-2">
            <div>
              <vue-2-dropzone
                id="dropzone"
                ref="universeUpload"
                class="mt-3"
                :options="dropzoneOptions"
                @vdropzone-removedfile="fileAdded"
                @vdropzone-success="fileUploaded"
                @vdropzone-error="uploadError"
                @vdropzone-file-added="fileSelected"
                @vdropzone-removed-file="fileRemoved"
              >
              </vue-2-dropzone>
              <div
                class="uploadButton"
                @click="universeBereinigen"
                :style="
                  flagFileSelected
                    ? 'background-color: #1B2C55; cursor: pointer;'
                    : 'background-color: #586483; cursor: auto'
                "
              >
                <p>Upload</p>
                <svg
                  id="upload_icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="22.216"
                  height="22.8"
                  viewBox="0 0 22.216 22.8"
                >
                  <path
                    id="Path_64"
                    data-name="Path 64"
                    d="M170.351,20.849l-4.677-4.677a.585.585,0,0,0-.827,0l-4.677,4.677a.585.585,0,1,0,.827.827L164.676,18V30.031a.585.585,0,0,0,1.169,0V18l3.679,3.679a.585.585,0,1,0,.827-.827Z"
                    transform="translate(-154.153 -16)"
                    fill="#fff"
                  />
                  <path
                    id="Path_65"
                    data-name="Path 65"
                    d="M11.108,300.86a16.2,16.2,0,0,1-7.725-1.786C1.2,297.862,0,296.212,0,294.429c0-3.088,3.657-5.791,8.7-6.426a.585.585,0,0,1,.159,1.158,9.419,9.419,0,0,0-3.941,1.44,2.762,2.762,0,0,0-1.406,2.074c0,.848.756,1.712,2.074,2.371a12.663,12.663,0,0,0,5.526,1.137,12.663,12.663,0,0,0,5.526-1.137c1.318-.659,2.074-1.523,2.074-2.371A2.762,2.762,0,0,0,17.3,290.6a9.419,9.419,0,0,0-3.941-1.44A.585.585,0,0,1,13.52,288c5.039.635,8.7,3.338,8.7,6.426,0,1.783-1.2,3.433-3.383,4.645A16.2,16.2,0,0,1,11.108,300.86ZM2.486,291.79a3.714,3.714,0,0,0-1.317,2.639c0,1.34.988,2.626,2.782,3.623a15.018,15.018,0,0,0,7.157,1.639,15.018,15.018,0,0,0,7.157-1.639c1.794-1,2.782-2.283,2.782-3.623a3.715,3.715,0,0,0-1.317-2.639,2.775,2.775,0,0,1,.148.885c0,1.326-.966,2.539-2.72,3.417a13.841,13.841,0,0,1-6.049,1.26,13.841,13.841,0,0,1-6.049-1.26c-1.754-.877-2.72-2.091-2.72-3.417A2.774,2.774,0,0,1,2.486,291.79Z"
                    transform="translate(0 -278.059)"
                    fill="#fff"
                  />
                </svg>
              </div>
              <div class="dropzoneIcon">
                <svg
                  id="drag_drop_icon"
                  data-name="drag&amp;drop_icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="48.617"
                  height="61.582"
                  viewBox="0 0 48.617 61.582"
                >
                  <path
                    id="Path_3945"
                    data-name="Path 3945"
                    d="M214.213,66.507a1.621,1.621,0,0,0-2.292,0l-6.957,6.957V33.621a1.621,1.621,0,0,0-3.241,0V73.464l-6.957-6.957a1.621,1.621,0,0,0-2.292,2.292l9.723,9.723a1.62,1.62,0,0,0,2.292,0l9.723-9.723A1.62,1.62,0,0,0,214.213,66.507Z"
                    transform="translate(-179.035 -32)"
                    fill="#8E97AF"
                  />
                  <path
                    id="Path_3946"
                    data-name="Path 3946"
                    d="M107.755,266.135H68.862A4.867,4.867,0,0,1,64,261.273V228.862A4.867,4.867,0,0,1,68.862,224H81.826a1.621,1.621,0,1,1,0,3.241H68.862a1.622,1.622,0,0,0-1.621,1.621v32.411a1.623,1.623,0,0,0,1.621,1.621h38.894a1.623,1.623,0,0,0,1.621-1.621V228.862a1.622,1.622,0,0,0-1.621-1.621H94.791a1.621,1.621,0,0,1,0-3.241h12.965a4.867,4.867,0,0,1,4.862,4.862v32.411A4.867,4.867,0,0,1,107.755,266.135Z"
                    transform="translate(-64 -204.553)"
                    fill="#8E97AF"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div class="col-1"></div>
          <div class="col-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="83.423"
              height="58.94"
              viewBox="0 0 83.423 58.94"
            >
              <g id="doubleArrow_icon" transform="translate(-674.499 -727.988)">
                <path
                  id="Polygon_2"
                  data-name="Polygon 2"
                  d="M27.732,3.047a2,2,0,0,1,3.475,0L57.235,48.695A2,2,0,0,1,55.5,51.685H3.443a2,2,0,0,1-1.737-2.991Z"
                  transform="translate(757.922 727.988) rotate(90)"
                  :fill="calculateColor"
                />
                <path
                  id="Polygon_3"
                  data-name="Polygon 3"
                  d="M27.732,3.047a2,2,0,0,1,3.475,0L57.235,48.695A2,2,0,0,1,55.5,51.685H3.443a2,2,0,0,1-1.737-2.991Z"
                  transform="translate(726.185 727.988) rotate(90)"
                  :fill="calculateColor"
                />
              </g>
            </svg>
          </div>
          <div class="col-2">
            <img
              src="https://arvea-dev.batix.ch/pic/17CC237C165_100x100.png"
              @click="oeffneCalculateModal"
            />
            <p>CALCULATE</p>
            <loading-overlay v-show="loading" :text="text"> </loading-overlay>
          </div>
          <div class="col-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="83.423"
              height="58.94"
              viewBox="0 0 83.423 58.94"
            >
              <g id="doubleArrow_icon" transform="translate(-674.499 -727.988)">
                <path
                  id="Polygon_2"
                  data-name="Polygon 2"
                  d="M27.732,3.047a2,2,0,0,1,3.475,0L57.235,48.695A2,2,0,0,1,55.5,51.685H3.443a2,2,0,0,1-1.737-2.991Z"
                  transform="translate(757.922 727.988) rotate(90)"
                  :fill="resultColor"
                />
                <path
                  id="Polygon_3"
                  data-name="Polygon 3"
                  d="M27.732,3.047a2,2,0,0,1,3.475,0L57.235,48.695A2,2,0,0,1,55.5,51.685H3.443a2,2,0,0,1-1.737-2.991Z"
                  transform="translate(726.185 727.988) rotate(90)"
                  :fill="resultColor"
                />
              </g>
            </svg>
          </div>
          <div class="col-1"></div>
          <div class="col-2">
            <h4>Results</h4>
            <div class="resultsDefault" v-if="!results">
              The downloadable files will be displayed here after the
              calculation.
            </div>
            <div class="results" v-if="results">
              <h4>Datasheet</h4>
              <p>Filename</p>
              <h5>Arvea-Universe.xlsx</h5>
              <img
                @click="download"
                src="https://arvea-dev.batix.ch/pic/17CEB201892_53x50.png"
              />
            </div>
            <hr v-if="results" size="2px" />
          </div>
        </div>
      </div>
      <calculate-modal
        :id="'calculate-modal'"
        :selectedEqs="selectedEquitiesAlertModal"
        :notSelectedEqs="notSelectedEquitiesAlertModal"
        :listings="listings"
        @calculate="universeBerechnen"
      />
      <!--
      <alert-modal
        :id="'alert-modal'"
        :title="titleConfirmModal"
        :text="textConfirmModal"
        :selectedEqs="selectedEquitiesAlertModal"
        :notSelectedEqs="notSelectedEquitiesAlertModal"
      />-->
    </div>
  </div>
  <!-- CALCULATOR ALT
  <div :key="key" class="mt-70-px">
    <navbar> </navbar>
    <notifications></notifications>
    <div v-if="angemeldeterMitarbeiter">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="tile-categorie-frame mt-5">
            <div class="tile-categorie">
              <div class="body">
                <vue-2-dropzone
                  id="dropzone"
                  ref="universeUpload"
                  class="mt-3"
                  :options="dropzoneOptions"
                  @vdropzone-removedfile="fileAdded"
                  @vdropzone-success="fileUploaded"
                  @vdropzone-error="uploadError"
                ></vue-2-dropzone>
              </div>
              <button
                class="btn btn-primary mt-2 mr-2"
                @click="universeBereinigen"
              >
                Upload
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div>
            <loading-overlay v-show="loading" :text="text"> </loading-overlay>
          </div>
        </div>

        <div class="row mt-5 justify-content-center">
          <equities-waehlen :listings="listings[0]"></equities-waehlen>
        </div>
      </div>
    </div>
    <alert-modal
      :id="id"
      :title="titleConfirmModal"
      :text="textConfirmModal"
      :selectedEqs="selectedEquitiesAlertModal"
      :notSelectedEqs="notSelectedEquitiesAlertModal"
    />
  </div>
  -->
</template>

<script>
import page from "@/mixins/Page";
import store from "@/store";
import FileDownload from "js-file-download";
import Api from "@/Api";
import vue2Dropzone from "vue2-dropzone";
import LoadingOverlay from "@/components/global/LoadingOverlay";
import { apiErrorToAlert } from "@/utils/Errorhandler";

import { uuidv4 } from "@/utils/Uuid";
import { dropzoneOptionsUniverse } from "@/config/Dropzone";
import CalculateModal from "../components/Modals/CalculateModal.vue";
import NavigationArvea from "@/components/Navigation/NavigationArvea";

export default {
  name: "Calculator",
  components: {
    //Navbar,
    vue2Dropzone,
    CalculateModal,
    //EquitiesWaehlen,
    LoadingOverlay,
    //AlertModal,
    NavigationArvea
  },
  mixins: [page],
  store,
  metaInfo() {
    return {
      titleTemplate: () => {
        return "Arvea Universe Calculator";
      }
    };
  },
  data() {
    return {
      name: "",
      text: "",
      salutation: "",
      titleConfirmModal: "Calculate",
      textConfirmModal: "",
      selectedEquitiesAlertModal: [],
      notSelectedEquitiesAlertModal: [],
      id: "calculate-modal",
      flagFileSelected: false,
      flagFileUploaded: false,
      flagFileAdded: false,
      flagFileCalculated: false,
      calculateColor: "#8E97AF",
      resultColor: "#8E97AF",
      results: false,
      key: uuidv4(),
      statistikAnmeldungen: null,
      excelFile: {},
      dropzoneOptions: dropzoneOptionsUniverse(Api.defaults.baseURL),
      listings: [[{ equity: "", listing: "" }]],
      loading: null
    };
  },

  watch: {
    flagFileCalculated: function() {
      this.resetLoading(this);
    }
  },

  computed: {
    permissions() {
      return this.$store.state.permissions;
    },
    statistiken() {
      return this.$store.state.belegstatistik;
    },
    selectedEquities() {
      return this.$store.state.selectedEquities;
    }
  },
  created() {},
  mounted() {},
  methods: {
    download() {
      FileDownload(this.excelFile, "Arvea-Universe.xlsx");
    },
    fileUploaded(file) {
      this.$store.state.selectedEquities = [];
      this.listings = JSON.parse(file.xhr.response);
      this.flagFileUploaded = true;
      this.calculateColor = "#213056";
    },
    fileSelected() {
      this.flagFileSelected = true;
    },
    fileRemoved() {
      this.flagFileSelected = false;
      this.calculateColor = "#8E97AF";
      this.resultColor = "#8E97AF";
    },
    uploadError(error) {
      this.$notify(apiErrorToAlert(error));
      this.$notify({
        type: "error",
        title: "Aktion fehlgeschlagen",
        text: "Fehler beim Hochladen der Datei. ",
        duration: 7000
      });
      this.flagFileUploaded = false;
    },

    universeBereinigen() {
      this.$refs.universeUpload.processQueue();
    },
    fileAdded() {
      this.flagFileAdded = true;
    },
    universeBerechnen(selected) {
      /* if (this.selectedEquities.length == 0) {
        this.$notify({
          type: "error",
          title: "Aktion fehlgeschlagen",
          text: "Keine Equities zum behalten ausgewählt",
          duration: 7000,
        });
        return;
      } */
      this.selectedEquities = selected;
      this.loading = true;
      if (this.flagFileUploaded) {
        let equitiesToKeep = Array.from(
          //new Set(this.$store.state.selectedEquities)
          new Set(this.selectedEquities)
        );
        /*this.$store.state.selectedEquities.forEach(element => {
          this.listings[0].filter((item) => element.row_index != item.row_index);
           });*/
        Api.put(
          "/user-input/",
          equitiesToKeep,
          { responseType: "arraybuffer" } //kann auch blob sein?
        )
          .then(
            this.$notify({
              type: "sucess",
              title: "Excel wird verarbeitet",
              text: "Bitte Warten. Dies kann einige Momente dauern",
              duration: 6000
            })
          )
          .then(response => {
            this.excelFile = new Blob([response.data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            });
            this.resultColor = "#213056";
            this.results = true;
            FileDownload(this.excelFile, "Arvea-Universe.xlsx");
            this.loading = false;
          })
          .catch(error => {
            console.log(error);
            setTimeout(() => {
              this.$notify({
                type: "error",
                title: "Aktion fehlgeschlagen",
                text:
                  "Fehler beim Erstellen der Excel-Datei \n -> Excel-Format überprüfen. Datei erneut hochladen.",
                duration: 7000
              });
            }, 2000);
            this.loading = false;
          });

        this.$store.state.selectedEquities = [];
        //  this.resetLoading(this)
      } else {
        this.$notify({
          type: "warn",
          title: "Keine Datei hochgeladen",
          text: "Bitte Excel-Datei hochladen",
          duration: 5000
        });
        this.loading = false;
      }

      //this.$bus.$emit("cleanTable");
    },
    hideModal() {
      $(".modal.in").modal("dispose");
      console.log("hiding in parent");
      //$("#calculate-modal").modal("hide");
    },
    modalConfirm() {
      $("#confirm-modal").modal("hide");

      //-> function to execute
      this.universeBerechnen();
    },
    modalCancel() {
      $("#confirm-modal").modal("hide");
    },

    schliesseConfirmModal() {
      $("#confirm-modal").modal("hide");
    },
    oeffneAlertModal() {
      $("#alert-modal").modal("show");
    },

    oeffneCalculateModal() {
      if (this.flagFileUploaded) {
        $("#calculate-modal").modal("show");
      }
    },
    erstelleListenFuerModal() {
      this.selectedEquitiesAlertModal = [];
      this.notSelectedEquitiesAlertModal = [];

      this.notSelectedEquitiesAlertModal = Array.from(this.listings[0]);

      let selectedEquities = Array.from(new Set(this.selectedEquities));

      console.log(
        "equties unselected initally: " + this.notSelectedEquitiesAlertModal
      );
      console.log("selectedEquities: " + selectedEquities);

      selectedEquities.forEach(element => {
        console.log("element: " + element);

        let equity = this.notSelectedEquitiesAlertModal.filter(obj => {
          return obj.row_index === element;
        });

        console.log("equity: " + equity);

        this.selectedEquitiesAlertModal.push(equity[0]);

        const index = this.notSelectedEquitiesAlertModal.indexOf(equity[0]);

        console.log("index: " + index);
        if (index > -1) {
          this.notSelectedEquitiesAlertModal.splice(index, 1);
        }
      });

      console.log(
        "equities unselected after selection: " +
          this.notSelectedEquitiesAlertModal
      );
    },
    startseite() {
      let startseite = this.$router.resolve({ name: "StartseiteArvea" });
      window.location.href = startseite.href;
    },
    routeOptions() {
      window.location.href = this.$router.resolve({ name: "Options" }).href;
    },
    routeCalculator() {
      let calculator = this.$router.resolve({ name: "Calculator" });
      window.location.href = calculator.href;
    },
    routeETF() {
      window.location.href = this.$router.resolve({ name: "ETF" }).href;
    },
    routePortfolio() {
      window.location.href = this.$router.resolve({ name: "Portfolio" }).href;
    }
  }
};
</script>

<style lang="scss">
body {
  background: white;
  color: #213056;
  overflow-x: hidden;
}
#calculator .banner {
  color: white;
  background-color: #586483;
  /*background: linear-gradient(218deg, #8e97af 10%, #586483 100%);*/
  height: 300px;
  width: 100vw;
}
#calculator .banner h1 {
  padding-top: 65px;
  font-weight: 700;
}
#calculator .banner p {
  padding-top: 10px;
  font-size: 16px;
  padding-right: 30%;
}
#calculator .banner > div:last-child {
  display: flex;
  align-items: center;
}
#calculator .wrapper > div:first-child {
  flex-wrap: nowrap;
}
#calculator .wrapper div:first-child div {
  margin-top: 50px;
  margin-bottom: -10px;
  display: flex;
}
#calculator .wrapper > div:first-child > div:not(:nth-child(2n)) {
  min-width: 150px;
}
#calculator .wrapper > div:first-child > div:nth-child(2n) > span {
  border-top: 1px solid #586483;
  width: 84%;
  margin-top: 25px;
  display: inherit;
}
#calculator .wrapper > div:first-child > div:nth-child(4) > span {
  margin-left: auto;
  margin-right: auto;
  width: 69%;
}
#calculator .wrapper > div:first-child > div:nth-child(3) {
  display: flex;
  justify-content: center;
}
/*
#calculator .wrapper > div:first-child div span {
  border-top: 1px solid #c5cbdc;
  width: 300px;
  position: relative;
  top: 23px;
  left: 27px;
  margin-right: 27px;
}
*/
#calculator .wrapper > div:first-child div p {
  color: #8e97af;
  font-size: 30px;
  font-weight: 700;
}
#calculator .wrapper > div:first-child div > p:first-child {
  color: #586483;
  margin-right: 8px;
}

#calculator .calculateTool {
  height: 500px;
  padding-left: 10vw;
  padding-right: 10vw;
}
#calculator .calculateTool > div:nth-child(2) {
  padding-top: 30px;
}
#calculator .calculateTool > div:nth-child(2) > div:first-child {
  padding-right: 0;
}
#calculator .calculateTool > div:nth-child(2) > div:first-child > div {
  display: block;
  margin-top: 0;
}
#calculator .vue-dropzone {
  background-color: transparent !important;
  border: 2px #8e97af dashed !important;
  height: 220px;
  position: relative;
  z-index: 2;
}
#calculator .vue-dropzone .dz-preview {
  position: absolute;
  width: 98.5%;
  height: 94%;
  left: -3.5%;
  bottom: 33%;
  font-family: Montserrat;
  margin-right: 0;
  margin-left: 4.5%;
}
#calculator .vue-dropzone .dz-progress {
  width: 100%;
  position: relative;
  left: -12%;
  margin-top: 70px !important;
  background: #c5cbdc;
}
#calculator .vue-dropzone .dz-upload {
  background: #8e97af;
}
#calculator .vue-dropzone .dz-details {
  border-radius: 17px;
  display: flex;
  flex-flow: column;
  align-items: center;
  background: linear-gradient(0deg, rgb(2, 0, 36) 0%, #223259 100%);
}
#calculator .vue-dropzone .dz-size {
  position: relative;
  bottom: -12%;
}
#calculator .vue-dropzone .dz-filename {
  position: relative;
  bottom: 80%;
  width: 100%;
}
#calculator .vue-dropzone .dz-filename > span {
  line-height: 100%;
}
#calculator .vue-dropzone .dz-remove {
  position: absolute;
  bottom: 80%;
  top: 30%;
  background-color: transparent;
  font-size: 18px;
  text-decoration: none;
}
#calculator .vue-dropzone .dz-error-mark {
  position: absolute;
  width: 10%;
  top: 50%;
  left: 44%;
}
#calculator .vue-dropzone .dz-success-mark {
  position: absolute;
  width: 10%;
  top: 50%;
  left: 44%;
}
#calculator .vue-dropzone .dz-error-message {
  display: none !important;
}
#calculator .uploadButton {
  background-color: #8e97af;
  color: white;
  position: relative;
  bottom: 90px;
  height: 50px;
  border-radius: 0 0 10px 10px;
  display: flex;
  align-items: center;
  z-index: 3;
}
#calculator .uploadButton p {
  margin: 0;
  margin-right: auto;
  padding-left: 17px;
  font-size: 1vw;
}
#calculator .uploadButton svg {
  margin-right: 17px;
  width: 1vw;
}
#calculator .dropzoneIcon {
  position: relative;
  bottom: 323px;
  display: flex;
  justify-content: center;
  //left: 95px;
}
#calculator .dropzoneIcon svg {
  width: 2vw;
  z-index: 0;
}
#calculator .dz-message span {
  padding-top: 25px;
  font-family: Montserrat;
  font-size: 1vw;
  color: #8e97af;
  font-weight: 500;
}
#calculator .calculateTool > div:nth-child(2) > div:nth-child(3),
.calculateTool > div:nth-child(2) > div:nth-child(5) {
  display: flex;
  justify-content: center;
  padding-top: 92px;
}
#calculator .calculateTool > div:nth-child(2) > div:nth-child(3) svg,
div:nth-child(5) svg {
  width: 4vw;
}
#calculator .calculateTool > div:nth-child(2) > div:nth-child(4) {
  text-align: center;
}
#calculator .calculateTool > div:nth-child(2) > div:nth-child(4) p {
  padding-top: 8px;
}
#calculator .calculateTool > div:nth-child(2) > div:nth-child(4) img {
  padding-top: 84px;
  width: 4vw;
  cursor: pointer;
}
#calculator .resultsDefault {
  color: #c5cbdc;
  border-left: 2px solid #c5cbdc;
  padding-left: 20px;
  margin-top: 15%;
}
#calculator .resultsDefault .h4 {
  margin-top: 1rem;
  color: #1b2c55;
  font-weight: 600;
}
#calculator .results {
  //border-bottom: 2px solid #8f98ae;
  color: #8e97af;
  margin-top: 30px;
}
#calculator .results > p {
  margin-bottom: 0;
  font-size: 0.9rem;
  font-weight: 500;
}
#calculator .results > h4 {
  color: #8e97af;
}
#calculator .results > h5 {
  font-weight: 500;
  padding-bottom: 10px;
  color: #213056;
  margin: 0;
  padding: 0;
}
#calculator .results > img {
  position: relative;
  left: 145px;
  bottom: 78px;
  width: 40px;
  cursor: pointer;
}
#calculator .calculateTool > div:nth-child(2) > div:last-child hr {
  margin: 0;
}
#calculator .loading-overlay .fade {
  transition: opacity 0.15s linear;
}
#calculator .overlay {
  position: fixed;
  opacity: 0.2;
}
#calculator .loading-overlay svg {
  font-size: 7vw;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -3.5vw;
  margin-top: 0.5vw;
}
/*
#calculator
  .calculateTool
  > div:nth-child(2)
  > div:last-child
  > div:last-child {
  border-bottom: 1px solid #8f98ae;
}*/
/*
#calculator .calculateTool > div:nth-child(2) > div:first-child > div {
  position: absolute;
}*/
/*
#calculator .vue-dropzone {
  border: 2px #8f98ae dashed !important;
  background-color: transparent !important;
  width: 250px;
  height: 220px;
  z-index: 2;
  color: #8f98ae;
  //border-radius: 20px 20px 0px 0px !important;
  //border-bottom: none !important;
}
#calculator .vue-dropzone span {
  margin-top: 25px;
}
#calculator .dropzoneIcon {
  position: absolute;
  left: 100px;
  bottom: 135px;
}

#calculator .calculateTool > div:nth-child(2) {
  height: 375px;
}
#calculator .calculateTool .uploadButton {
  background-color: #8f98ae;
  color: white;
  position: relative;
  z-index: 1;
  right: 250px;
  top: 133px;
  width: 250px;
  height: 53px;
  border-radius: 0 0 10px 10px;
}
#calculator .calculateTool .uploadButton {
  padding: 13px;
  padding-left: 18px;
  font-size: 16px;
  font-weight: 500;
}
#calculator .calculateTool .uploadButton svg {
  margin-left: auto;
  margin-right: 5px;
  padding-right: 5px;
} 
#calculator .calculateTool > div:nth-child(2) > div:nth-child(2) > div > div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  height: 375px;
}
#calculator
  .calculateTool
  > div:nth-child(2)
  > div:nth-child(2)
  > div
  > div
  > img {
  height: 55px;
} 
#calculator .calculateTool > div:nth-child(2) > div:nth-child(2) {
  display: flex;
}
#calculator .calculateTool > div:nth-child(2) > div:nth-child(2) > div {
  margin-top: -100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#calculator .calculateTool > div:nth-child(2) > div:nth-child(2) > div > div {
  position: relative;
}
#calculator
  .calculateTool
  > div:nth-child(2)
  > div:nth-child(2)
  > div
  > div:nth-child(2) {
  left: 18px;
}
#calculator
  .calculateTool
  > div:nth-child(2)
  > div:nth-child(2)
  > div
  > div:first-child
  svg {
  position: absolute;
  right: 75px;
  height: 50px;
  bottom: 180px;
}
#calculator
  .calculateTool
  > div:nth-child(2)
  > div:nth-child(2)
  > div
  > div:last-child
  svg {
  position: absolute;
  left: 112px;
  height: 50px;
  bottom: 180px;
}
#calculator .calculateTool > div:nth-child(2) > div:last-child > h4 {
  padding-top: 65px;
}
#calculator
  .calculateTool
  > div:nth-child(2)
  > div:last-child
  > div:first-of-type {
  border-left: 2px solid #c5cbdc;
  color: #c5cbdc;
  margin-top: 30px;
  padding-left: 20px;
  font-weight: 600;
}
#calculator .dz-preview {
  position: relative;
  left: -234px;
  bottom: 117px;
  height: 199px;
  width: 241px;
  font-family: Montserrat;
}
#calculator .dz-remove {
  position: absolute;
  top: 54px;
  background-color: transparent;
  border-radius: 7px;
  color: white;
  text-decoration: none;
  font-size: 20px;
}
#calculator .dz-details {
  border-radius: 15px;
  background-color: #213056;
}
/*
#calculator
  .calculateTool
  > div:nth-child(2)
  > div:nth-child(2)
  > div
  > div:last-child {
  margin-right: 250px;
}
#calculator
  .calculateTool
  > div:nth-child(2)
  > div:nth-child(2)
  > div
  > div:first-child,
div:last-child {
}*/
</style>
