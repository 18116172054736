<template>
  <div class="row">
    <div :class="[ colClass ]" class="block-dark block-kopf-dark">
      <span class="primary-headline-text text-white">{{ headline }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeadMenuFilter",
  props: {
    headline: {
      type: String,
      required: true,
    },
    col: {
      type: Number,
      required: false,
      default: 2,
    },
  },
  computed: {
    colClass: function () {
      if (this.col) {
        return "col-xl-" + this.col;
      } else {
        return "col-xl-2";
      }
    },
  },
};
</script>

<style>
</style>
