<template>
  <div :id="id" class="modal fade">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="row modal-header">
          <h2 class="modal-title">
            Multiples Listed<span @click="closeModal"
              ><i class="fal fa-times"></i
            ></span>
          </h2>
          <p>Choose Equities To Keep</p>
        </div>

        <div class="modal-body">
          <equities-waehlen
            v-if="showList"
            :listings="listings[0]"
          ></equities-waehlen>

          <div v-if="!showList">
            <div class="row equitiesBestaetigen">
              <div class="col">
                <b-table
                  ref="tableSelectedEqs"
                  small
                  tbody-tr-class="item"
                  :items="selectedEquitiesAlertModal"
                  :fields="fieldsSelectedEqs"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  sort-icon-left
                  fixed
                  select-mode="range"
                ></b-table>
              </div>

              <div class="col">
                <b-table
                  ref="tableNotSelectedEqs"
                  small
                  tbody-tr-class="item"
                  :items="notSelectedEquitiesAlertModal"
                  :fields="fieldsNotSelectedEqs"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  sort-icon-left
                  fixed
                  select-mode="range"
                ></b-table>
              </div>
            </div>
          </div>

          <!--
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 class="modal-title">
            <i v-if="color" class="fa mr-2" :class="[icon, textColor]"></i>
            {{ title }}
          </h4>
        </div>
        <div class="modal-body">
          <p>{{ text }}</p>
          <div class="row">
            <div class="col">
              <b-table
                ref="tableSelectedEqs"
                small
                tbody-tr-class="item"
                :items="selectedEqs"
                :fields="fieldsSelectedEqs"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                sort-icon-left
                fixed
                select-mode="range"
              ></b-table>
            </div>

            <div class="col">
              <b-table
                ref="tableNotSelectedEqs"
                small
                tbody-tr-class="item"
                :items="notSelectedEqs"
                :fields="fieldsNotSelectedEqs"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                sort-icon-left
                fixed
                select-mode="range"
              ></b-table>
            </div>
          </div>
        </div>
        -->
          <div class="modal-footer text-center">
            <div @click="closeModal"><span>Cancel</span></div>
            <div v-show="!showList" @click="back"><span>Back</span></div>
            <div v-show="showList" @click="apply">
              <span>Apply Selection</span>
            </div>
            <div v-show="!showList" @click="berechnen">
              <span>Calculate</span>
            </div>
            <!--
        <button type="button" class="btn btn-default" @click="hide">
          Cancel
        </button>
        <button type="button" class="btn btn-primary" @click="close">Ok</button>
        -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "bootstrap/dist/js/bootstrap";
import { modal } from "../../mixins/Modal";
import ModalCloseButton from "@/components/global/ModalCloseButton";
import page from "@/mixins/Page";

import EquitiesWaehlen from "@/components/EquitiesWaehlen";

export default {
  name: "CalculateModal",
  components: {
    EquitiesWaehlen,
  },
  mixins: [modal, page],
  props: {
    id: {
      type: String,
      required: true,
      default: "calculate-modal",
    },
    color: {
      type: String,
      required: false,
      default: null,
    },
    eventName: {
      type: String,
      default: "",
    },
    selectedEqs: {
      type: Array,
      default: null,
    },
    notSelectedEqs: {
      type: Array,
      default: null,
    },
    listings: {
      type: Array,
      required: false,
      //default: null,
    },
  },
  data() {
    return {
      sortBy: "equity",
      sortDesc: false,
      fieldsNotSelectedEqs: [
        { key: "equity", sortable: true, label: "Will be deleted" },
      ],

      fieldsSelectedEqs: [{ key: "equity", sortable: true, label: "Selected" }],
      showList: true,
      selectedEquitiesAlertModal: [],
      notSelectedEquitiesAlertModal: [],
    };
  },
  computed: {
    icon: function () {
      if (this.color) {
        if (this.color === "success") return "fa-check-circle-o";
        if (this.color === "warning") return "fa-warning";
        if (this.color === "danger") return "fa-times-circle-o";
      }
      return null;
    },
    textColor: function () {
      if (this.color) return `text-${this.color}`;
      return null;
    },
    permissions() {
      return this.$store.state.permissions;
    },
    statistiken() {
      return this.$store.state.belegstatistik;
    },
    selectedEquities() {
      return this.$store.state.selectedEquities;
    },
  },
  methods: {
    openModal() {
      this.shown = true;
      $("#" + this.id).modal("show");
    },
    closeModal() {
      this.shown = false;
      $("#" + this.id).modal("hide");
    },
    back() {
      this.showList = true;
    },
    berechnen() {
      console.log("bin am berechnen");
      //this.$emit("calculate");
      this.closeModal();
      this.$parent.universeBerechnen(this.selectedEquitiesAlertModal);
    },
    apply() {
      this.showList = false;

      this.selectedEquitiesAlertModal = [];
      this.notSelectedEquitiesAlertModal = [];

      this.notSelectedEquitiesAlertModal = Array.from(this.listings[0]);

      let selectedEquities = Array.from(new Set(this.selectedEquities));

      console.log(
        "equties unselected initally: " + this.notSelectedEquitiesAlertModal
      );
      console.log("selectedEquities: " + selectedEquities);

      selectedEquities.forEach((element) => {
        console.log("element: " + element);

        let equity = this.notSelectedEquitiesAlertModal.filter((obj) => {
          return obj.row_index === element;
        });

        console.log("equity: " + equity);

        this.selectedEquitiesAlertModal.push(equity[0]);

        const index = this.notSelectedEquitiesAlertModal.indexOf(equity[0]);

        console.log("index: " + index);
        if (index > -1) {
          this.notSelectedEquitiesAlertModal.splice(index, 1);
        }
      });

      console.log(
        "equities unselected after selection: " +
          this.notSelectedEquitiesAlertModal
      );
    },
  },
};
</script>

<style>
#calculate-modal .modal-content {
  margin: 5% auto 2% auto;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.3);
  background: #8f98ae;
  border-radius: 7px;
}
#calculate-modal .modal-header {
  background: #c5cbdc;
  height: 125px;
  border-radius: 7px 7px 0 0;
  display: flex;
  flex-flow: column;
  padding-top: 29px;
  padding-left: 47px;
  position: relative;
  z-index: 5;
  align-items: normal;
  box-shadow: 0px 5px 12px -1px rgba(0, 0, 0, 0.15);
  margin-left: 0;
  margin-right: 0;
  border: none;
}
#calculate-modal .modal-header {
  padding: 15px 0 0 75px;
}
#calculate-modal .modal-header h2 {
  margin-bottom: 2px;
  color: #8e97af;
  font-weight: 700;
  font-size: 27px;
}
#calculate-modal .modal-header p {
  font-weight: 500;
  text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1);
  font-size: 19px;
  color: #213056;
}
#calculate-modal .modal-header span {
  color: white;
  float: right;
  padding-right: 20px;
  margin-top: -17px;
  cursor: pointer;
}
#calculate-modal .modal-body {
  padding-top: 0;
}
#calculate-modal .modal-footer {
  border: none;
  font-weight: 500;
  font-size: 14px;
  color: white;
}
#calculate-modal .modal-footer > div:first-child {
  background: #c5cbdc;
  width: 10vw;
  border-radius: 3px;
  padding: 5px;
  margin-right: auto;
  cursor: pointer;
}
#calculate-modal .modal-footer > div:nth-child(2) {
  background: #c5cbdc;
  width: 10vw;
  border-radius: 3px;
  padding: 5px;
  cursor: pointer;
}
#calculate-modal .modal-footer > div:nth-child(3) {
  background: #213056;
  width: 10vw;
  border-radius: 3px;
  padding: 5px;
  cursor: pointer;
}
#calculate-modal .modal-footer > div:nth-child(4) {
  background: #213056;
  width: 10vw;
  border-radius: 3px;
  padding: 5px;
  cursor: pointer;
}
#calculate-modal .equitiesBestaetigen {
  background: white;
  height: 60vh;
  overflow-y: scroll;
  scrollbar-color: #8e97af #c5cbdc;
  scrollbar-width: thin;
}
#calculate-modal #equitiesWaehlen .row {
  margin-left: -0.98rem;
}
#calculate-modal .equitiesBestaetigen th {
  border-top: none;
}
</style>